import * as React from 'react';
import gql from "graphql-tag";
import {Mutation, Query} from '@apollo/client/react/components';
import * as Yup from 'yup';
import {Formik, ErrorMessage} from 'formik';
import styled from "styled-components";
import {Box} from "@rebass/grid";
import {Button} from "rebass";
import {v4 as Guid} from 'uuid';
import { navigate } from 'gatsby';

const GqlQuery = gql`
    query getContacts {
        contacts {
            value {
                emails {
                    value
                }
            }
        }
    }
`

const GqlMutation = gql`
    mutation addContact($input: Input!) {
        addContact(input: $input) {
            value {
                emails {
                    value
                }
            }
        }
    }
`

const FormSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required')
})

class Test extends React.Component {
    public render() {
        return (
            <div style={{color: 'white'}}>
                <h1>Get Data from Provider</h1>
                <Query query={GqlQuery} onError={e => console.log('There was an error', e)}>
                    {(res) => {
                        console.log('Query Response', res.data);
                        return res.data && res.data.contacts.map(d =>
                            <ul key={Guid()}>
                                {d.value.emails.map(e =>
                                    <li key={Guid()}>{e.value}</li>
                                )}
                            </ul>
                        ) || <>Loading...</>
                    }}
                </Query>

                <h1>Mutation</h1>
                <Mutation mutation={GqlMutation}>
                    {(addContact, result) => {
                        let submitted: boolean = false;

                        return !submitted && (
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                validationSchema={FormSchema}
                                onSubmit={(values, actions) => {
                                    console.log('Input values:', values);

                                    const inputProps = {
                                        value: {
                                            emails: [
                                                {
                                                    value: values.email
                                                }
                                            ]
                                        }
                                    }

                                    console.log('inputProps: ', inputProps)

                                    addContact({
                                        variables: {
                                            input: inputProps
                                        }
                                    }).then(r => {
                                        if (r.data.addContact.value) {
                                            // navigate('/');
                                            submitted = true;
                                        } else {
                                            actions.setErrors({ email: 'Could not post to response' });
                                        }
                                    })
                                }}
                                render={(
                                    {
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        isSubmitting,
                                        errors,
                                        touched
                                    }) =>
                                    <form onSubmit={handleSubmit}>
                                        <ErrorMessage name={"form"}/>
                                        <FormGroup p={[2,3]}>
                                            <label htmlFor={'email'}>Email</label>
                                            <input
                                                id={'email'}
                                                type={'email'}
                                                name={'email'}
                                                value={values.email}
                                                onChange={handleChange}
                                                placeholder={'test@example.com'}
                                                required={true}
                                            />
                                            <ErrorMessage name={'email'} />
                                        </FormGroup>
                                        <Box>
                                            <Button
                                                disabled={(isSubmitting || !!(errors.email && touched.email)) ?? false}
                                                style={{ color: 'red' }}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </form>
                                }
                            />
                        ) || <p>Thank you! We'll be in touch shortly.</p>
                    }}
                </Mutation>
            </div>
        )
    }
}

export default Test;

const FormGroup = styled(Box)`
  border-bottom: 1px solid silver;
`
